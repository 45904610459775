import React from 'react'
import styles from './layout.module.scss'
import Header from '../layout/headerRenter'
import Footer from '../layout/footerRenter'
import NavBar from '../navBar'
import AuthProvider from '../../provider/auth'
import News from './news'

const Layout = props => {
  return (
    <div>
      <News />
      <div className={styles.layout_renter}>
        <AuthProvider>
          <Header />
          <NavBar />
          <div className="bg-primary">
            <div className="container">
              <div className={styles.content}>{props.children}</div>
            </div>
          </div>
        </AuthProvider>
        <Footer />
      </div>
    </div>
  )
}
export default Layout
