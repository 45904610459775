import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

function SEO({ description, lang, meta }) {
  const { datoCmsSite } = useStaticQuery(
    graphql`
      query {
        datoCmsSite {
          globalSeo {
            siteName
            titleSuffix
            twitterAccount
            facebookPageUrl
            fallbackSeo {
              title
              description
              image {
                url
              }
              twitterCard
            }
          }
        }
      }
    `
  )
  const metaDescription = description || datoCmsSite.globalSeo.fallbackSeo.description
  const title = datoCmsSite.globalSeo.siteName + datoCmsSite.globalSeo.titleSuffix
  const twitterCard = datoCmsSite.globalSeo.fallbackSeo.twitterCard

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${datoCmsSite.globalSeo.siteName}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: twitterCard
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
}

export default SEO
