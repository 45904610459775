import React from 'react'
import styles from './marketing.module.scss'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import SEO from '../components/common/seo'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { CLOCK, LIKE, MOBILE } from '../components/icons'

const Terms = ({ data: { markdownRemark, datoCmsTermsPage, datoCmsSite } }) => {
  const { benefits } = datoCmsTermsPage.benefits
  benefits[0].icon = <MOBILE />
  benefits[1].icon = <CLOCK />
  benefits[2].icon = <LIKE />
  return (
    <div>
      <SEO />
      <HelmetDatoCms favicon={datoCmsSite.faviconMetaTags} />
      <Layout>
        <div className="row bg-primary">
          <div className="col-12">
            <div className={`${styles.content} bg-white mb-5`}>
              <div className="about">
                <hr className={styles.about_special} />
                <h2 className={styles.about_title}>{datoCmsTermsPage.title}</h2>
              </div>
              <div className="terms text-left">
                <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Terms

export const query = graphql`
  query TermsMarkdown {
    markdownRemark(parent: { id: { regex: "/DatoCmsTermsPage/" } }) {
      html
    }
    datoCmsTermsPage {
      title
      benefits {
        benefits {
          id
          title
          description
        }
      }
    }
    datoCmsSite {
      faviconMetaTags {
        tags
      }
    }
  }
`
